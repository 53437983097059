var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-wrapper-table"},[_c('div',{staticClass:"page-wrapper-table-header"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":_vm.$t('COMMON.PER_PAGE')},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),(
          !_vm.filterOrganization &&
          !_vm.filterInvoice &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        )?_c('organization-selector',{on:{"organizationChanged":(organizationId) => (_vm.selectedOrganization = organizationId)}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"placeholder":`${_vm.$t('COMMON.LOCATIONS')}`}},[_c('locations-selector',{on:{"locationsChanged":(locations) => (_vm.selectedLocations = locations)}})],1):_vm._e(),_c('base-input',{attrs:{"type":"search","prepend-icon":"fas fa-search","placeholder":_vm.$t('COMMON.SEARCH'),"clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('div',{staticClass:"page-wrapper-table-body"},[_c('div',{staticClass:"page-wrapper-table-body-inner"},[_c('el-table',{staticClass:"align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.salesPayments,"row-class-name":"cursor-pointer"},on:{"sort-change":_vm.sortChange,"row-click":(row) => {
              _vm.viewSalesPayment(row);
            }}},[(_vm.loading)?_c('div',{staticClass:"table-loading",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":"Code","min-width":"80px","prop":"code"}}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.DATE'),"prop":"date","sortable":"date","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(row.date, "LLLL"))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.STATUS'),"prop":"status","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('salesPayment-status-badge',{attrs:{"salesPayment":row}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.AMOUNT'),"prop":"amount","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.$formatCurrency(row.amount))+" ")]}}])}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.LOCATIONS'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('locations',{attrs:{"locations":row.allowedLocations}})]}}],null,false,4110342845)}):_vm._e(),(
              !_vm.filterOrganization &&
              !_vm.filterInvoice &&
              _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"prop":"organization","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('organization',{attrs:{"organization":row.organization}})]}}],null,false,3772690256)}):_vm._e(),(!_vm.filterInvoice)?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.INVOICE'),"prop":"invoice.code","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('router-link',{attrs:{"to":_vm.$objectViewRoute(row.invoice.id)}},[_vm._v(" "+_vm._s(row.invoice ? row.invoice.code : null)+" ")])]}}],null,false,31722482)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.CREATED_AT'),"prop":"created_at","sortable":"custom","min-width":"220"}}),_c('el-table-column',{attrs:{"fixed":_vm.$listActionsButtonsPosition(),"min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{class:{
                  disabled: !_vm.$currentUserCan(
                    _vm.$permissions.PERM_VIEW_SALES_PAYMENTS
                  ),
                },attrs:{"content":_vm.$t('COMMON.VIEW'),"placement":"top"}},[_c('a',{staticClass:"table-action",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.viewSalesPayment(row)}}},[_c('i',{staticClass:"fal fa-expand-alt"})])])],1)}}])})],1)],1)]),_c('div',{staticClass:"page-wrapper-table-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('p',{staticClass:"card-category"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", { from: _vm.total ? _vm.from + 1 : 0, to: _vm.to, of: _vm.total, }))+" "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.$t("COMMON.X_LINES_SELECTED", { count: _vm.selectedRows.length }))+" ")]):_vm._e()]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }